import React from "react";

const UseCasesHero = () => {
  return (
    <div className="pt-[30px] md:pt-[40px] lg:pt-[49px]">
      <p className="text-center text-[24px] sm:text-[28px] md:text-[32px] lg:text-[38px] text-white font-poppins font-semibold tracking-wide">
        Delve into the Experience of Manufacturers
      </p>
      <p className="text-center text-[12px] md:text-[14px] lg:text-[19px] text-white font-poppins tracking-wide">
        Explore some of the challenges faced by manufacturers & how DFOS{" "}
        <br className="hidden sm:block" /> assisted them discover the solution.
      </p>
    </div>
  );
};

export default UseCasesHero;
