import React from "react";
import { LinkedinShareButton, TwitterShareButton } from "react-share";
import twitter from "../Assets/Images/twitter.svg";
import linkedin from "../Assets/Images/linkedin.svg";

const DetailsOfBlog = () => {
  const shareUrl = "https://designx.in/IIOT";
  const title = "IIOT";
  return (
    <div className="mx-[15px] sm:mx-[30px] md:mx-[50px] xl:mx-[80px] mt-[52px] pb-[50px]">
      <p className="text-[#C8C8C8] text-[14px] lg:text-[18px] font-poppins">
        In the digital age, the manufacturing industry is shifting towards
        automation to transform itself. In this race of transformation, the
        industrial internet of things can bring many opportunities for
        manufacturing firms. Yes, from process automation to supply chain
        management to industrial asset tracking, IIOT can assist businesses in
        using valuable real-time data for their success! With IIOT, one can
        easily find problems, detect errors, and banish inefficiencies. This
        step towards industry 4.0 will help us reach the maximum benefits. Let
        us learn more about IIOT and how we can empower you with these
        solutions.
      </p>
      <p className="text-[#C8C8C8] text-[14px] lg:text-[18px] font-poppins">
        Presently, DFOS measures and analyses data in three different ways: the
        transfer of data from paper to DFOS, machines to DFOS, you to DFOS.
      </p>

      <p className="text-[#FCFCFC] text-[20px] md:text-[24px] lg:text-[28px] mt-[40px] font-poppins font-medium">
        What is Industrial Internet of Things?
      </p>
      <p className="text-[#C8C8C8] text-[14px] lg:text-[18px] font-poppins">
        In this race to switch to an automated version, the manufacturing
        industry is seeking help from the IOT (Internet of Things). IOT
        interconnects a variety of physical objects and technologies to ensure
        seamless communication. IIOT is an extended version of IOT. The
        connected devices on the IIOT network can transfer data flawlessly
        without any human-to-human or human-to-computer interactions. In simple
        terms, it connects intelligent devices that collect, analyze, and
        interpret data to help businesses leverage the benefits.
      </p>
      <p className="text-[#C8C8C8] text-[14px] lg:text-[18px] font-poppins">
        IIOT is helping businesses embrace the advantages of smart solutions and
        digital tools. But how can it help manufacturing industry and shop
        floors? Let us find out.
      </p>

      <p className="text-[#FCFCFC] text-[20px] md:text-[24px] lg:text-[28px] mt-[40px] font-poppins font-medium">
        How is IIOT helpful for the manufacturing industry?
      </p>

      <p className="text-[#C8C8C8] text-[14px] lg:text-[18px] font-poppins">
        Manufacturing hubs are stepping towards automation and artificial
        intelligence to grow at an exceptional speed. But surviving in this
        ultra-competitive space is a herculean task, as one needs to juggle
        multiple operations at once. Thus, understanding the importance of the
        industrial internet of things is highly crucial. Now you must be
        wondering how IIOT can help the manufacturing industry. Here is the
        answer:{" "}
      </p>

      <p className="text-[#FCFCFC] text-[18px] md:text-[20px] lg:text-[24px] mt-[40px] font-poppins font-medium">
        Data Accessibility
      </p>
      <p className="text-[#C8C8C8] text-[14px] lg:text-[18px] font-poppins">
        By using the IIOT solution, one can have complete access to any data
        from a specific centre location. It will help manufacturing hubs gain
        more remote control over inventory and stocks. Additionally, it promotes
        the process of monitoring and securing assets. So now you can have great
        control over resources and see them from a distance.
      </p>

      <p className="text-[#FCFCFC] text-[18px] md:text-[20px] lg:text-[24px] mt-[40px] font-medium">
        Market Know-how
      </p>
      <p className="text-[#C8C8C8] text-[14px] lg:text-[18px] font-poppins ">
        Knowledge about market data is highly important for the manufacturing
        industry, and with IIOT, one can easily generate actionable insights
        from effective data mining. This data can help shop floors with better
        operational quality management, understanding output patterns, and so
        forth. Hence, market forecasting can help businesses achieve success.
      </p>

      <p className="text-[#FCFCFC] text-[18px] md:text-[20px] lg:text-[24px] mt-[40px] font-poppins font-medium">
        Productive Manufacturing
      </p>
      <p className="text-[#C8C8C8] text-[14px] lg:text-[18px] font-poppins ">
        Real-time data can help increase the productivity of manufacturing
        units. The end product is affected by economic conditions, the process
        used, quality decisions, and many other factors. These captured
        analytics can help in making timely decisions to eliminate wastage and
        ensure the production is done as per schedule & as per the plan. Hence,
        IIOT can help in productive manufacturing.
      </p>

      <p className="text-[#FCFCFC] text-[18px] md:text-[20px] lg:text-[24px] mt-[40px] font-poppins font-medium">
        Better Equipment Utilization
      </p>
      <p className="text-[#C8C8C8] text-[14px] lg:text-[18px] font-poppins ">
        Utilizing machinery to its maximum output is a difficult task for many
        manufacturing units. However, with IIOT machine management solutions,
        you can gain equipment effectiveness, and productivity. So, your
        organization’s profitability will automatically increase.
      </p>
      <p className="text-[#C8C8C8] text-[14px] lg:text-[18px] font-poppins  font-medium">
        So, these are the benefits which manufacturing firms can enjoy after
        choosing IIOT solutions. Now, let us understand how DFOS empower you
        with IIOT solutions & how we are helping manufacturing units to go
        global with automation.
      </p>

      <p className="text-[#FCFCFC] text-[20px] md:text-[24px] lg:text-[28px] mt-[40px] font-poppins font-medium">
        How DFOS uses IIOT?
      </p>
      <p className="text-[#C8C8C8] text-[14px] lg:text-[18px] font-poppins ">
        DFOS is a DIY platform where one can create a tailor-made automation
        solution for their business. With this platform, you can select several
        sets of solutions that you find relevant for your business. Using the
        deep tech technologies like IIOT, AI, RPA, and iBPM, DFOS help your
        manufacturing unit switch to smart manufacturing. Being an intuitive UI
        platform, DFOS is easy to use and a highly scalable platform. So, you
        can create need-specific dashboards.
      </p>
      <p className="text-[#C8C8C8] text-[14px] lg:text-[18px] font-poppins ">
        With our platform, you can reap the benefits of Industry 4.0 and be one
        step closer to becoming a completely automated shop floor. So go digital
        with DFOS!.
      </p>

      <p className="text-[#FCFCFC] text-[20px] md:text-[24px] lg:text-[28px] mt-[40px] font-poppins font-medium">
        The Bottom Line
      </p>
      <p className="text-[#C8C8C8] text-[14px] lg:text-[18px] font-poppins ">
        In today’s environment Industrial Internet of Things environment,
        challenges, opportunities, and directions are everywhere. But, knowing
        how to use it effectively for your organization is tough. Thus, to help
        you with it, we have created a platform – DFOS. Furthermore, the
        industrial internet of things can be used in many ways. Explore them
        today with DesignX!
      </p>

      <div className="fixed bottom-[120px] right-5 z-12">
        <TwitterShareButton url={shareUrl} title={title}>
          <img src={twitter} className="w-[45px] h-[45px]" alt="twitter" />
        </TwitterShareButton>
      </div>
      <div className="fixed bottom-[65px] right-5 z-12">
        <LinkedinShareButton url={shareUrl} title={title}>
          <img src={linkedin} className="w-[45px] h-[45px]" alt="linkedin" />
        </LinkedinShareButton>
      </div>
    </div>
  );
};

export default DetailsOfBlog;
