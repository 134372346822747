import React from "react";
import ESGDetail from "../Assets/Images/ESGDetail.png";
import { Link } from "react-router-dom";
import { LinkedinShareButton, TwitterShareButton } from "react-share";
import twitter from "../Assets/Images/twitter.svg";
import linkedin from "../Assets/Images/linkedin.svg";
const ESG = () => {
  const shareUrl = "https://designx.in/ESG";
  const title = "ESG";
  return (
    <div className="blogback">
      <div className="blogHeaderBack pt-[75px]">
        <div className="block lg:grid lg:grid-cols-2 gap-4 relative z-[1]">
          <div className="mx-[30px] md:mx-[50px] xl:mx-[80px] py-[20px]">
            <p className="text-white text-[14px] lg:text-[18px] font-inter mt-[31px]">
              <Link to="/" className="homeButton">
                Home
              </Link>{" "}
              /{" "}
              <Link to="/Blogs" className="homeButton">
                Blog
              </Link>
            </p>
            <p className="text-white text-[22px] md:text-[32px] xl:text-[42px] font-robotoSlab">
              How can Factories Meet ESG Goals?
            </p>
          </div>
          <div className="hidden lg:flex lg:justify-end">
            <img src={ESGDetail} alt="" />
          </div>
        </div>
      </div>
      <div className="mx-[30px] md:mx-[50px] xl:mx-[80px] mt-[52px] pb-[50px]">
        <p className="text-[#C8C8C8] text-[14px] lg:text-[18px] font-poppins">
          Sustainability is a buzz word these days as Gen Z pays heed to the
          approach followed by factories to manufacture a product. In addition,
          profit maximization, solely, can’t make a business successful, they
          need to follow certain ethical and sustainable business practices to
          survive in the long run. This buzz for sustainability brings our
          attention to ESG (Environmental, Social, and Governance), a standard
          set to guide companies to become more accountable and responsible for
          their environmental and social impact.
        </p>
        <p className="text-[#C8C8C8] text-[14px] lg:text-[18px] font-poppins">
          With the use of ESG metrics, factories try to up their game and get
          ahead of their competitors. So, what’s your strategy to meet ESG
          standards & step towards sustainability? Not planned yet? No worries!
          Today, let us learn about the ESG initiatives benefits, and we will
          also find out how factories can easily meet the ESG goals.
        </p>

        <p className="text-[#FCFCFC] text-[20px] md:text-[24px] lg:text-[28px] mt-[40px] font-poppins font-medium">
          What is ESG?
        </p>
        <p className="text-[#C8C8C8] text-[14px] lg:text-[18px] font-poppins">
          ESG or Environmental, Social, and Governance are the three main pillar
          frameworks. Under it, there are certain areas where companies are
          expected to report. These standards were created in 2005 by the
          world’s largest banks and institutional investors to guide companies
          to follow specific conducts while manufacturing. Also, after the ESG
          framework was coined, companies were expected to pay more attention
          towards environmental, social & organizational governance. Here are
          the three major pillars of ESG:
        </p>

        <p className="text-[#C8C8C8] text-[14px] lg:text-[18px] font-poppins">
          <span className="font-semibold">Environment:</span> This focuses to
          minimize factory's environmental footprints. It includes reduction of
          energy consumption and pollution, more use of renewable resources,
          adoption of sustainable production processes, and conservation of
          natural resources.
        </p>
        <p className="text-[#C8C8C8] text-[14px] lg:text-[18px] font-poppins">
          <span className="font-semibold">Social:</span> It's all about people
          and the community. This ensures fair wages and benefits, a safe and
          healthy work environment, promotion of diversity and inclusion, and
          engagement with and support of the local community.
        </p>
        <p className="text-[#C8C8C8] text-[14px] lg:text-[18px] font-poppins">
          <span className="font-semibold">Governance:</span> This pillar
          emphasizes ethical and transparent business practices. It involves
          strong corporate governance, robust risk management, anti-corruption
          measures, and clear communication with stakeholders. 
        </p>

        <p className="text-[#FCFCFC] text-[20px] md:text-[24px] lg:text-[28px] mt-[40px] font-medium">
          Benefits of ESG Initiatives: The Power of "Going Green" 
        </p>
        <p className="text-[#C8C8C8] text-[14px] lg:text-[18px] font-poppins ">
          The ESG waves are across industries and factories with no exception.
          Many of them have actively incorporated ESG metrics into their
          valuations, recognized the long-term benefits of sustainable
          practices. But what are these benefits? Let’s have a look at some of
          them:
        </p>
        <p className="text-[#FCFCFC] text-[16px] md:text-[20px] lg:text-[24px] mt-[40px] font-poppins font-medium">
          Environmental Stewardship
        </p>
        <p className="text-[#C8C8C8] text-[14px] lg:text-[18px] font-poppins ">
          A reduction in the environmental impact can contribute to a healthier
          planet and mitigate climate change risks. This not only aligns with
          social responsibility but can also lead to cost savings through
          efficient resource utilization.
        </p>

        <p className="text-[#FCFCFC] text-[16px] md:text-[20px] lg:text-[24px] mt-[40px] font-poppins font-medium">
          Enhanced Brand Reputation
        </p>
        <p className="text-[#C8C8C8] text-[14px] lg:text-[18px] font-poppins ">
          Nowadays, consumers choose brands that align with their values &
          environmental ethics. This demonstrates that the commitment to ESG can
          boost the brand’s image and customer loyalty, which leads to greater
          market share and profitability. Furthermore, it helps attract a larger
          audience to your brand.
        </p>

        <p className="text-[#FCFCFC] text-[16px] md:text-[20px] lg:text-[24px] mt-[40px] font-poppins font-medium">
          Reduced Risks and Regulatory Compliance
        </p>
        <p className="text-[#C8C8C8] text-[14px] lg:text-[18px] font-poppins ">
          Factories can adhere to ESG standards and avoid fines as well as legal
          problems associated with environmental or social non-compliance. It
          also positions your factory for future regulations focused on
          sustainability.
        </p>

        <p className="text-[#FCFCFC] text-[16px] md:text-[20px] lg:text-[24px] mt-[40px] font-poppins font-medium">
          Energy Efficiency & Waste Reduction
        </p>
        <p className="text-[#C8C8C8] text-[14px] lg:text-[18px] font-poppins ">
          One can easily complete investment in energy-efficient equipment,
          utilization of natural lighting, and renewable energy sources like
          solar power. In addition, factories can implement a comprehensive
          waste management program that encourages reuse, recycling and compost.
          Minimize production waste and explore ways to repurpose or upcycle
          leftover materials with ESG initiatives.
        </p>
        <p className="text-[#FCFCFC] text-[20px] md:text-[24px] lg:text-[28px] mt-[40px] font-poppins font-medium">
          Why is it important for factories to meet the ESG standards?
        </p>
        <p className="text-[#C8C8C8] text-[14px] lg:text-[18px] font-poppins ">
          Environmental, Social, and Governance (ESG) initiatives have become
          increasingly important for factories worldwide. These initiatives aim
          to address the environmental impact of manufacturing, improve social
          conditions for workers and communities, and ensure ethical and
          transparent business practices.
        </p>
        <p className="text-[#C8C8C8] text-[14px] lg:text-[18px] font-poppins">
          There are several reasons why it's important for factories to meet the
          ESG initiatives:
        </p>
        <ul className="list-disc">
          <li className="text-[#C8C8C8] text-[14px] lg:text-[18px] font-poppins">
            Implementation of practices such as energy efficiency, waste
            reduction, and pollution control can reduce the environmental impact
            of factories. This can help to mitigate climate change, conserve
            resources, and protect the environment.
          </li>
          <li className="text-[#C8C8C8] text-[14px] lg:text-[18px] font-poppins">
            Facilities like wages and benefits, safe work conditions, and
            opportunities for training and development can improve the social
            conditions of workers. This can help to reduce poverty, improve
            worker well-being, and create a more positive work environment.
          </li>
          <li className="text-[#C8C8C8] text-[14px] lg:text-[18px] font-poppins">
            Governments around the world increasingly implement regulations that
            require companies to meet certain ESG standards. Factories can
            reduce the risk of fines and other penalties if these standards are
            met.
          </li>
        </ul>
        <p className="text-[#C8C8C8] text-[14px] lg:text-[18px] font-poppins ">
          Studies have shown that companies with strong ESG practices tend to
          outperform companies with weak ESG practices. This is because ESG
          practices can lead to cost savings, increased efficiency, and improved
          employee morale.
        </p>
        <p className="text-[#C8C8C8] text-[14px] lg:text-[18px] font-poppins ">
          So, take the first step today and start an ESG journey. Your factory,
          your employees, your community, and the planet will thank you for it!
        </p>
      </div>
      <div className="fixed bottom-[120px] right-5 z-12">
        <TwitterShareButton url={shareUrl} title={title}>
          <img src={twitter} className="w-[45px] h-[45px]" alt="twitter" />
        </TwitterShareButton>
      </div>
      <div className="fixed bottom-[65px] right-5 z-12">
        <LinkedinShareButton url={shareUrl} title={title}>
          <img src={linkedin} className="w-[45px] h-[45px]" alt="linkedin" />
        </LinkedinShareButton>
      </div>
    </div>
  );
};

export default ESG;
